'use client';

import { useParams } from 'next/navigation';
import { useTransition } from 'react';
import { useTranslations } from 'next-intl';
import { useRouter, usePathname } from '~/navigation';

import Image from 'next/image';
import { Button } from '~/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover';

export default function RegionSwitcher() {
  const t = useTranslations();
  const params = useParams();
  const locale = params.locale || 'en';

  const locales = [
    {
      id: 'et',
      name: 'estonian',
      flag: 'ee'
    },
    {
      id: 'en',
      name: 'english',
      flag: 'gb'
    },
    {
      id: 'ru',
      name: 'russian',
      flag: 'ru'
    }
  ];

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="ghost" className="capitalize text-gray-500">
          <div className="h-[20px] w-[20px] relative mr-2 shadow-lg rounded-full overflow-hidden">
            <Image
              src={`https://flagcdn.com/${locales.find((loc) => loc.id === locale)?.flag}.svg`}
              alt="flag"
              fill
              priority
              className="object-cover"
            />
          </div>
          {t(locales.find((loc) => loc.id === locale)?.name)}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-[150px]">
        <div className="flex flex-col">
          <LanguageOption locale="et" label={t('estonian')} flagUrl="https://flagcdn.com/ee.svg" />
          <LanguageOption locale="en" label={t('english')} flagUrl="https://flagcdn.com/us.svg" />
          <LanguageOption locale="ru" label={t('russian')} flagUrl="https://flagcdn.com/ru.svg" />
        </div>
      </PopoverContent>
    </Popover>
  );
}

const LanguageOption = (props: { locale: string; label: string; flagUrl: string }) => {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();
  const params = useParams();

  function onSelectChange() {
    const nextLocale = props.locale;
    startTransition(() => {
      router.replace(
        pathname,
        { locale: nextLocale }
      );
    });
  }

  return (
    <Button className="w-full capitalize" variant="ghost" onClick={onSelectChange}>
      <div className="h-[20px] w-[20px] relative mr-2 shadow-lg rounded-full overflow-hidden">
        <Image src={props.flagUrl} alt={props.label} fill className="object-cover" priority />
      </div>
      {props.label}
    </Button>
  );
};
