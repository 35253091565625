'use client';

import { useState } from 'react';
import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { parseAsBoolean, useQueryState } from 'nuqs';

import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { Typography } from '../ui/typography';
import { toast } from 'react-toastify';
import { Loader2 } from 'lucide-react';

const emailRegex =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function AuthProviders(props: { redirect?: string; clientSide?: boolean }) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const t = useTranslations();

  const logInWithGoogle = async () => {
    try {
      await signIn('google', {
        ...(!!props?.redirect && { callbackUrl: props?.redirect })
      });
      // if (props.clientSide) setAuthOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const logInWithEmail = async () => {
    if (email.length === 0) {
      toast.error('Please enter your email address');
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    setIsLoading(true);

    try {
      await signIn('brevo', {
        email: email,
        ...(!!props?.redirect && { callbackUrl: props?.redirect })
      });
      // if (props.clientSide) setAuthOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col">
      <Button className="w-full" variant="outline" onClick={logInWithGoogle}>
        <div className="h-[24px] w-[24px] mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            />
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            />
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            />
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            />
            <path d="M1 1h22v22H1z" fill="none" />
          </svg>
        </div>
        Log in with Google
      </Button>
      <div className="flex flex-row w-full items-center my-6">
        <div className="shrink-0 bg-border h-[1px] flex-auto" />
        <Typography variant="body" className="px-6 text-slate-500 uppercase text-sm">
          {t('or')}
        </Typography>
        <div className="shrink-0 bg-border h-[1px] flex-auto" />
      </div>
      <div className="flex flex-col gap-4">
        <Label htmlFor="email">Email</Label>
        <Input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Button className="w-full" onClick={logInWithEmail} disabled={isLoading}>
          {isLoading ? <Loader2 size={18} className="animate-spin text-white" /> : t('logInWithEmail')}
        </Button>
      </div>
    </div>
  );
}
