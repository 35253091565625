'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '~/components/ui/sheet';
import { Button } from '~/components/ui/button';
import { MenuIcon, Home, User, Store, Car, Calendar, Plus, HelpCircle, Info, History } from 'lucide-react';
import AdminMenuButton from '~/components/admin/menu/button';
import RegionSwitcher from '../region-switcher';

const menuItems = [
  {
    name: 'Koduleht',
    icon: <Home />,
    href: '/'
  }
];

export default function MobileMenu(props: { isLoggedIn: boolean }) {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    {
      name: t('home'),
      icon: <Home />,
      href: '/'
    },
    {
      name: props.isLoggedIn ? t('myAccount') : t('log-in'),
      icon: <User />,
      href: '/account'
    },
    {
      name: t('auctions'),
      icon: <Car />,
      href: '/search'
    },
    {
      name: t('endedAuctions'),
      icon: <History />,
      href: '/search?status=ended'
    },
    {
      name: t('calendar'),
      icon: <Calendar />,
      href: '/calendar'
    },
    {
      name: t('about'),
      icon: <Info />,
      href: '/about'
    },
    {
      name: t('faqMobileMenu'),
      icon: <HelpCircle />,
      href: '/frequently-asked-questions'
    },
    {
      name: t('listYourVehicle'),
      icon: <Plus />,
      href: '/account/listings'
    }
  ];

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button size="icon" variant="ghost" className="text-gray-500">
          <MenuIcon size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className="h-100vh flex flex-col p-0 gap-0" hideClose>
        <SheetHeader className="px-6 py-3 flex flex-row justify-between items-center">
          <SheetTitle className="text-left -mb-2">{t('menu')}</SheetTitle>
          <RegionSwitcher />
        </SheetHeader>
        <div className="shrink-0 bg-border h-[1px] w-full" />
        <div className="px-2 pt-4">
          {menuItems.map((menuItem, i) => (
            <div
              key={i}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <AdminMenuButton key={i} name={menuItem.name} icon={menuItem.icon} href={menuItem.href} />
            </div>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
}
