'use client';

import { api } from '~/trpc/react';
import { notFound } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Card } from '~/components/ui/card';
import { Typography } from '~/components/ui/typography';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~/components/ui/select';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { Skeleton } from '~/components/ui/skeleton';
import { toast } from 'react-toastify';

import { RouterOutputs } from '~/trpc/shared';

function SettingsForm(props: { user: RouterOutputs['user']['get'][number], callback?: Function }) {
  const t = useTranslations();
  const _user = props.user;
  const mutation = api.user.update.useMutation();

  const formSchema = z.object({
    name: z
      .string()
      .min(3, { message: t('nameMustBeThreeChars') })
      .max(255, { message: t('nameMustBeFiftyChars') }),
    businessName: z.string().optional(),
    vatNumber: z.string().optional(),
    registrationCode: z.string().optional(),

    address: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zipCode: z.string().optional(),
    country: z.string().optional(),

    type: z.string(),
    phone: z
      .string()
      .regex(
        /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
        { message: t('phoneInvalid') }
      )
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: _user.name,
      phone: _user.phone || '',
      businessName: _user.businessName || '',
      type: _user.type || 'individual',
      vatNumber: _user.vatNumber || '',
      registrationCode: _user.registrationCode || '',
      address: _user.address || '',
      city: _user.city || '',
      state: _user.state || '',
      zipCode: _user.zipCode || '',
      country: _user.country || ''
    }
  });
  const typeWatcher = form.watch('type');

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await mutation.mutateAsync({ ...values });
      toast.success(t('profileUpdateSuccess'));
      if(!!props.callback) {
        props.callback();
      }
    } catch (error) {
      console.error(error);
      toast.error(t('profileUpdateError'));
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid sm:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>{t('name')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem className="w-full sm:col-span-2">
                <FormLabel>{t('accountType')}</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('selectAccountType')} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="individual">{t('individual')}</SelectItem>
                    <SelectItem value="company">{t('company')}</SelectItem>
                    <SelectItem value="state">{t('state')}</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          {typeWatcher === 'company' && (
            <>
              <FormField
                control={form.control}
                name="businessName"
                render={({ field }) => (
                  <FormItem className="sm:col-span-2">
                    <FormLabel>{t('businessName')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="vatNumber"
                render={({ field }) => (
                  <FormItem className="sm:col-span-2">
                    <FormLabel>{t('vatNumber')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="registrationCode"
                render={({ field }) => (
                  <FormItem className="sm:col-span-2">
                    <FormLabel>{t('companyRegistrationNumber')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>{t('phone')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormDescription>{t('phoneDescription')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>{t('address')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>{t('city')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>{t('state')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="zipCode"
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>{t('zip')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>{t('country')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button disabled={mutation.isLoading} className="w-full mt-4" type="submit">
          {t('saveProfile')}
        </Button>
      </form>
    </Form>
  );
}

export default function EditProfile(props: {callback?: Function}) {
  const { data: user, isLoading } = api.user.get.useQuery();

  if (isLoading) {
    return <Skeleton className="w-full h-[450px]" />;
  }
  if (user === undefined || user[0] === undefined) {
    return null;
  }
  if (!!user && user.length === 0) {
    notFound();
  }

  return <SettingsForm user={user[0]} callback={props.callback}/>;
}
