'use client';

import { api } from '~/trpc/react';
import { useTranslations } from 'next-intl';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '~/components/ui/dialog';
import EditProfile from '../account/edit-profile';

export default function FinishSettingUp(props: { isAuthed: boolean }) {
  const t = useTranslations();
  const { data: user, isLoading, refetch } = api.user.get.useQuery(undefined, { enabled: props.isAuthed });
  const isOpen = !!user && user[0] ? !user[0]?.phone && !isLoading : false;

  return (
    <Dialog open={isOpen}>
      <DialogContent hideCloseButton className="overflow-y-auto h-screen max-h-[700px]">
        <DialogHeader>
          <DialogTitle>{t('finishSettingUp')}</DialogTitle>
          <DialogDescription>{t('finishSettingUpDescription')}</DialogDescription>
        </DialogHeader>
        <EditProfile
          callback={() => {
            console.log('refetch');
            refetch();
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
