'use client';

import { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import cookies from 'js-cookie';

import { Dialog, DialogContent } from '~/components/ui/dialog';
import { Button } from '~/components/ui/button';
import { Typography } from '~/components/ui/typography';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '~/components/ui/form';
import { Switch } from '~/components/ui/switch';
import { useTranslations } from 'next-intl';

const FormSchema = z.object({
  necessary: z.boolean(),
  statistics: z.boolean(),
  marketing: z.boolean(),
  unclassified: z.boolean()
});

export default function CookieConsent() {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  const [manageCookies, setManageCookies] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      necessary: true,
      statistics: true,
      marketing: true,
      unclassified: true
    }
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    let consent: string[] = [];
    Object.keys(data).forEach((key) => {
      if (data[key as keyof typeof data]) {
        consent.push(key);
      }
    });
    cookies.set('cookieConsent', consent.join(','), { expires: 365 });
    setOpen(false);
  };

  useEffect(() => {
    if (!cookies.get('cookieConsent')) {
      setOpen(true);
    }
  }, []);

  return (
    <Dialog open={open}>
      <DialogContent hideCloseButton>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
            {manageCookies ? (
              <div className="flex flex-col gap-2">
                <Typography variant="h3" className="text-xl">
                  {t('manageCookies')}
                </Typography>
                <FormField
                  control={form.control}
                  name="necessary"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">{t('necessaryCookies')}</FormLabel>
                        <FormDescription>{t('necessaryCookiesDescription')}</FormDescription>
                      </div>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} disabled aria-readonly />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="statistics"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">{t('statisticsCookies')}</FormLabel>
                        <FormDescription>{t('statisticsCookiesDescription')}</FormDescription>
                      </div>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} aria-readonly />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="marketing"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">{t('marketingCookies')}</FormLabel>
                        <FormDescription>{t('marketingCookiesDescription')}</FormDescription>
                      </div>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} aria-readonly />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="unclassified"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">{t('unClassifiedCookies')}</FormLabel>
                        <FormDescription>{t('unClassifiedCookiesDescription')}</FormDescription>
                      </div>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} aria-readonly />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className="flex flex-col gap-2">
                  <Button type="submit">{t('saveCookieOptions')}</Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <Typography variant="h3" className="text-xl">
                  {t('weUseCookies')}
                </Typography>
                <Typography className="opacity-70">{t('weUseCookiesDescription1')}</Typography>
                <Typography className="opacity-70 pb-4">{t('weUseCookiesDescription2')}</Typography>
                <div className="flex flex-col gap-2">
                  <Button type="submit">{t('acceptAllCookies')}</Button>
                  <Button
                    variant="outline"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setManageCookies(true);
                    }}
                  >
                    {t('manageCookies')}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      cookies.set('cookieConsent', "declined", { expires: 365 });
                      setOpen(false);
                    }}
                  >
                    {t('declineCookies')}
                  </Button>
                </div>
              </div>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
