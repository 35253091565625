import { Link } from '~/navigation';
import React from 'react';
import { Button } from '~/components/ui/button';
import { Badge } from '~/components/ui/badge';
type MenuButtonProps = {
  name: string;
  icon: React.ReactNode;
  size?: number;
  className?: string;
  active?: boolean;
  href?: string;
  decoration?: string;
  basePath?: string;
};

export default function AdminMenuButton({
  name,
  icon,
  size = 18,
  className = '',
  active = false,
  href = '/admin',
  decoration = '',
  basePath
}: MenuButtonProps) {
  return (
    <Link
      href={
        href.includes('https://') ? href : href.includes('api/auth') ? href : !!basePath ? `${basePath}/${href}` : href
      }
      target={href.includes('https://') ? '_blank' : '_self'}
    >
      <Button
        variant={active ? 'default' : 'ghost'}
        className={`w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center ${
          active ? 'text-white bg-gray-900 hover:bg-gray-800 hover:text-white' : 'text-gray-800 hover:bg-gray-200'
        } ${className}`}
      >
        <div className="flex flex-row items-center">
          {React.cloneElement(icon as React.ReactElement, { size, className: 'mr-2' })}
          {name}
        </div>
        <div>
          {decoration.length > 0 && (
            <Badge className="bg-primary py-0 px-1 min-w-[18px] text-center justify-center">{decoration}</Badge>
          )}
        </div>
      </Button>
    </Link>
  );
}
