'use client';

import { api } from '~/trpc/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { useRouter } from '~/navigation';

import { Link } from '~/navigation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Dialog, DialogContent, DialogTrigger } from '~/components/ui/dialog';
import { Button } from '~/components/ui/button';
import { Bike, Car, Search, Tractor } from 'lucide-react';
import Excavator from '~/components/icons/excavator';
import { Input } from '~/components/ui/input';
import { Typography } from '~/components/ui/typography';
import { Box } from 'lucide-react';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { customEncodeURI } from '~/utils/encode-uri';

const searchSchema = z.object({
  searchQuery: z.string().max(255)
});

export default function SearchDialog() {
  const router = useRouter();
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const form = useForm<z.infer<typeof searchSchema>>({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      searchQuery: ''
    }
  });
  const searchQuery = form.watch('searchQuery');
  const { data: productSearchResults } = api.product.dialogSearch.useQuery({
    searchQuery: searchQuery
  });

  const debouncedProductSearchResults = useDebounce(productSearchResults, 300);

  const handleNavigate = () => {
    form.setValue('searchQuery', '');
    setIsOpen(false);
  };

  function onSubmit(values: z.infer<typeof searchSchema>) {
    router.push(`/search/?query=${encodeURIComponent(values.searchQuery)}`);
    // setFilter({ searchTerm: values.searchQuery }, '/search');
    setIsOpen(false);
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <div>
          <Button variant="ghost" size="icon" className="sm:hidden text-gray-500">
            <Search size={20} />
          </Button>
          <Button className="hidden sm:inline-flex items-center whitespace-nowrap transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground px-4 py-2 relative h-8 w-full justify-start rounded-[0.5rem] bg-background text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40 lg:w-64 mr-3">
            <span className="hidden lg:inline-flex">{t('searchProducts')}...</span>
            <span className="inline-flex lg:hidden">{t('search')}...</span>
            <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.3rem] hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
              <Search size={13} />
            </kbd>
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] p-0 gap-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-row items-center justify-between border-b relative">
              <Search size={20} className="text-gray-500 absolute left-4" />
              <FormField
                control={form.control}
                name="searchQuery"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={t('typeToSearch')}
                        className="pl-12 py-6 border-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 flex h-10 w-full rounded-md bg-transparent text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
        <div className="pb-2 pt-2 h-[calc(100vh-200px)] max-h-[360px] overflow-y-auto">
          {searchQuery.length === 0 ? (
            <div className="px-2">
              <Typography className="px-4 " variant="caption">
                {t('categories')}
              </Typography>
              <div className="flex flex-col">
                <Link href={'/search/?categories=6bcd0655-917e-4639-ae30-45ad720d7e28'}>
                  <Button
                    variant="ghost"
                    className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                  >
                    <div className="flex flex-row items-center">
                      <Car className="mr-2" size={18} />
                      {t('Cars')}
                    </div>
                  </Button>
                </Link>
                <Link href={'/search/?categories=9b89c58e-1c25-4b54-a76b-79a31618da5e'}>
                  <Button
                    variant="ghost"
                    className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                  >
                    <div className="flex flex-row items-center">
                      <Tractor className="mr-2" size={18} />
                      {t('Heavy machinery')}
                    </div>
                  </Button>
                </Link>
                <Link href={'/search/?categories=0564aecb-822c-4bed-9cfb-a66e06b70a42'}>
                  <Button
                    variant="ghost"
                    className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                  >
                    <div className="flex flex-row items-center">
                      <Bike className="mr-2" size={18} />
                      {t('Mototechnics')}
                    </div>
                  </Button>
                </Link>
                <Link href={'/search/?categories=605b9f2c-dec5-4777-ab20-8b198ebb2c4c'}>
                  <Button
                    variant="ghost"
                    className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                  >
                    <div className="flex flex-row items-center">
                      <Tractor className="mr-2" size={18} />
                      {t('Agro')}
                    </div>
                  </Button>
                </Link>
                <Link href={'/search/?categories=669fc121-75c9-49a1-b5e7-62a7f00b0b95'}>
                  <Button
                    variant="ghost"
                    className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200"
                  >
                    <div className="flex flex-row items-center">
                      <Excavator className="mr-2 w-[18px] h-[18px] stroke-2" />
                      {t('Construction')}
                    </div>
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <>
              {(debouncedProductSearchResults?.length || 0) > 0 && (
                <div className="px-2">
                  <Typography className="px-4 " variant="caption">
                    {t('auctions')}
                  </Typography>
                  {debouncedProductSearchResults?.map((product) => (
                    <Link
                      key={product.product.id}
                      href={`/listing/${customEncodeURI(product.product.handle)}`}
                      onClick={handleNavigate}
                    >
                      <Button
                        variant="ghost"
                        className="w-full flex flex-row text-sm justify-between py-5 rounded-lg items-center text-gray-800 hover:bg-gray-200 overflow-hidden"
                      >
                        <div className="flex flex-row items-center">
                          <Box className="mr-2" size={18} />
                          {product.product.name.length > 45
                            ? product.product.name.slice(0, 45) + '...'
                            : product.product.name}
                        </div>
                      </Button>
                    </Link>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
