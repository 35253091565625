'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { Link } from '~/navigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu';
import { Button } from '~/components/ui/button';
import { ChevronDown, ChevronUp } from 'lucide-react';

export default function MobileMenuExtension({ headerLink }: { headerLink: { name: string; href: string }[] }) {
  const t = useTranslations();
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <Button variant="ghost" size="icon">
          <ChevronDown size={18} className={`transition-all ${open && '-rotate-180'}`} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-screen relative top-4">
        <DropdownMenuLabel>{t("groupCompanies")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {headerLink.map((linkItem) => (
          <Link key={linkItem.name} href={linkItem.href} target="_blank">
            <DropdownMenuItem>{linkItem.name}</DropdownMenuItem>
          </Link>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
