import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.13.0_next@14.2.1_react@18.2.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.13.0_next@14.2.1_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/auth/auth-popup.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/auth/finish-setting-up-account.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/auth/login-button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shop/cookie-consent.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shop/menu/mobile-menu-extension.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/shop/menu/mobile-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shop/region-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/shop/search/search-dialog.tsx");
