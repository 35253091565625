'use client';

import { useTranslations } from 'next-intl';
import { parseAsBoolean, useQueryState } from 'nuqs';

import { UserCircle } from 'lucide-react';
import { Button } from '~/components/ui/button';

export default function LoginButton() {
  const t = useTranslations();
  const [_, setAuthOpen] = useQueryState('authOpen', parseAsBoolean);

  return (
    <Button
      size="sm"
      variant="ghost"
      className="text-secondary-foreground/60"
      onClick={() => {
        setAuthOpen(true);
      }}
    >
      <UserCircle className="mr-2" size={18} /> {t('log-in')}
    </Button>
  );
}
