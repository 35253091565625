'use client';

import { parseAsBoolean, useQueryState } from 'nuqs';
import { useTranslations } from 'next-intl';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '~/components/ui/dialog';
import AuthProviders from './auth-providers';

export default function AuthPopup(props: { isAuthed: boolean }) {
  const t = useTranslations();
  const [authOpen, setAuthOpen] = useQueryState('authOpen', parseAsBoolean);

  return (
    <Dialog open={(authOpen && !props.isAuthed) || false} onOpenChange={setAuthOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("loginOrRegister")}</DialogTitle>
          <DialogDescription>
            {t("log-in-text")}
          </DialogDescription>
        </DialogHeader>
        <AuthProviders clientSide />
      </DialogContent>
    </Dialog>
  );
}
